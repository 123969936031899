<script lang="ts">
	import { browser } from '$app/environment'
	import { onDestroy } from 'svelte'

	interface Props {
		photos?: string[] // Array of photo URLs
		interval?: number // Interval between photo changes in milliseconds
		vertical?: boolean
	}

	let { photos = [], interval = 1000, vertical = false }: Props = $props()

	let isLoaded = $state(false)

	let startedLoading = $state(false)

	let firstPhoto = $derived(photos?.[0])
	$effect(() => {
		if (browser && firstPhoto && !startedLoading) {
			startedLoading = true
			const img = new Image()
			img.onerror = (err) => {
				console.warn('Image load error:', err)
			}
			img.onload = () => {
				isLoaded = true
			}

			img.src = firstPhoto
		}
	})

	let currentIndex = $state(0)
	let timer: number | null = null

	let activePhoto = $state(photos?.[0])
	$effect(() => {
		activePhoto = photos?.[currentIndex]
	}) // This creates a reactive statement

	function nextPhoto() {
		currentIndex = (currentIndex + 1) % photos?.length
		activePhoto = photos?.[currentIndex]
	}

	function startLoop() {
		if (!timer) {
			timer = window.setInterval(nextPhoto, interval)
		}
	}

	function stopLoop() {
		if (timer) {
			clearInterval(timer)
			timer = null
		}
		currentIndex = 0 // Reset to the first photo when not hovering
		activePhoto = photos[0]
	}

	onDestroy(() => {
		if (timer) clearInterval(timer)
	})
</script>

<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
	class={`w-full h-full relative overflow-hidden ${vertical ? '' : 'rounded-lg'}
    transition-opacity duration-500 ease-in-out ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
	onmouseenter={startLoop}
	onmouseleave={stopLoop}
>
	{#each photos as photo, index}
		{@const isActive = photo === activePhoto}
		<img
			src={photo}
			alt={`Photo ${index + 1}`}
			class={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ease-in-out ${
				isActive ? 'opacity-100' : 'opacity-0'
			}`}
		/>
	{/each}
</div>
